<template>
  <div>
    <SectionHeader title="Pricing statistics" title-icon="fal fa-analytics" />
    <div class="box columns" v-if="valueComparison">
      <div class="field column is-narrow">
        <p class="is-size-7-mobile">Average listing price</p>
        <p class="is-size-4 is-size-6-mobile has-text-primary">
          {{ pounds(averagePrice) }}
        </p>
      </div>
      <div class="field column is-narrow">
        <p class="is-size-7-mobile">Total listed value of stock</p>
        <p class="is-size-4 is-size-6-mobile has-text-primary">
          {{ pounds(totalPrice) }}
        </p>
        <p class="tag is-light is-rounded" :class="`is-${icon.color}`">
          <span>{{ comparison }}</span>
          <span class="icon is-small">
            <i :class="`fas ${icon.icon} has-text-${icon.color}`" />
          </span>
        </p>
      </div>
      <div class="field column is-narrow">
        <p class="is-size-7-mobile">Market price of listed stock</p>
        <p class="is-size-4 is-size-6-mobile has-text-primary">
          {{ pounds(totalSuggestedPrice) }}
        </p>
      </div>
    </div>
    <TableEmpty v-else text="No data found for your branch" size="small" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatNumber } from 'accounting'
import { pounds } from 'core/utils/format'
export default {
  name: 'StockviewPricingStats',
  components: {
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    TableEmpty: () => import('core/components/TableEmpty')
  },
  computed: {
    ...mapGetters('stockview/dashboard', [
      'averagePrice',
      'totalPrice',
      'totalSuggestedPrice',
      'valueComparison'
    ]),
    comparison() {
      const { percentageDifference, valueDifference, status, onMarket } =
        this.valueComparison

      if (onMarket) return 'On market'

      const percent = formatNumber(percentageDifference, 2)
      const value = this.pounds(valueDifference)

      return `${percent}% (${value}) ${status} market`
    },
    icon() {
      const { status, onMarket } = this.valueComparison
      if (onMarket) return { icon: 'fa-check', color: 'success' }
      else if (status === 'below')
        return { icon: 'fa-caret-down', color: 'danger' }
      else return { icon: 'fa-caret-up', color: 'danger' }
    }
  },
  methods: {
    pounds
  }
}
</script>
